@import 'styles.sass';

#care-org-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    #care-org-basic-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $padding-large;
        margin-bottom: $margin-large;
        & > div, & > h1 {
            margin: $margin-medium 0; }
        #save-button {
            width: fit-content;
            align-self: end; } }
    #care-org-features {
        display: flex;
        padding: $padding-large;
        margin-bottom: $margin-large;
        span {
            display: flex;
            align-items: center;
            margin-right: $margin-medium; } }
    #care-org-users {
        padding: $padding-large; } }

#create-care-user-inputs {
    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: $margin-medium; } }
