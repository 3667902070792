@font-face {
  font-family: "PoppinsLight";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SofiaProLight";
  src: local("SofiaPro"), url(./fonts/SofiaPro/Sofia-Pro-Light.otf) format("truetype");
}

@font-face {
  font-family: "SofiaProRegular";
  src: local("SofiaPro"), url(./fonts/SofiaPro/Sofia-Pro-Regular.otf) format("truetype");
}

@font-face {
  font-family: "SofiaProMedium";
  src: local("SofiaPro"), url(./fonts/SofiaPro/Sofia-Pro-Medium.otf) format("truetype");
}
@font-face {
  font-family: "SofiaProBold";
  src: local("SofiaPro"), url(./fonts/SofiaPro/Sofia-Pro-Bold.otf) format("truetype");
}
@font-face {
  font-family: "SofiaProSemiBold";
  src: local("SofiaPro"), url(./fonts/SofiaPro/Sofia-Pro-Semi-Bold.otf) format("truetype");
}
