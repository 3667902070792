// Globally used styles such as color definitions go here

$admin-color-primary: #0B5563;
$admin-background-color: #f4f4f4;

$margin-large: 32px;
$margin-medium: 16px;

$padding-large: 32px;
$padding-medium: 16px;
