@import 'styles.sass';

#family-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    #family-basic-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $padding-large;
        margin-bottom: $margin-large;
        & > div, & > h1 {
            margin: $margin-medium 0; }
        #save-button {
            width: fit-content;
            align-self: end; } }
    #family-clients {
        padding: $padding-large; } }
