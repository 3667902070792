@import 'styles.sass';

#family-list {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    text-align: center;
    padding: $padding-large;
    a {
        text-decoration: none; }
    #family-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin-medium;
        button {
            height: fit-contents; } }
    #family-table {
        margin-top: $margin-medium; } }
