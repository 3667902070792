@import 'styles.sass';

#create-super-admin {
    padding: $margin-large;
    width: 100%;
    height: fit-content;
    #create-super-admin-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div, & > h1 {
            margin: $margin-medium 0; }
        #save-button {
            width: fit-content;
            align-self: end; } } }
